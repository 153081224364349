import { getBridgeAssets } from '../bridge/get-assets';

// eslint-disable-next-line no-shadow
export enum Networks {
  freeTon = 'free-ton',
  bsc = 'bsc',
  polygonMainnet = 'polygon',
  // fantomOpera = 'fantom-opera',
  ethereumMainnet = 'ethereum',
  avalancheNetwork = 'avalanche',
  // milkomedaC1Mainnet = 'milkomeda',
}

export type NetworkOption = {
  currencySymbol: string;
  value: string;
  label: string;
  shortLabel?: string;
  chainId: number;
  rpcUrl: string;
};

export const everNetworkOption: NetworkOption = {
  currencySymbol: 'EVER',
  value: Networks.freeTon as string,
  label: 'Everscale',
  chainId: 1,
  rpcUrl: '',
};

export const networkOptionsMap: Record<string, NetworkOption> = {
  56: {
    currencySymbol: 'BNB',
    value: Networks.bsc as string,
    label: 'BNB Chain (Binance Smart Chain)',
    shortLabel: 'BNB Chain',
    chainId: 56,
    rpcUrl: 'https://bsc-dataseed.binance.org/',
  },
  1: {
    currencySymbol: 'ETH',
    value: Networks.ethereumMainnet as string,
    label: 'Ethereum',
    chainId: 1,
    rpcUrl: 'https://mainnet.infura.io/v3/',
  },
  137: {
    currencySymbol: 'MATIC',
    value: Networks.polygonMainnet as string,
    label: 'Polygon',
    chainId: 137,
    rpcUrl: 'https://matic-mainnet.chainstacklabs.com/',
  },
  // 250: { value: Networks.fantomOpera as string, label: 'Fantom Opera', shortLabel: 'Fantom', chainId: 250 },
  43114: {
    currencySymbol: 'AVAX',
    value: Networks.avalancheNetwork as string,
    label: 'Avalanche',
    shortLabel: 'Avalanche',
    chainId: 43114,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
  },
  // 2001: { value: Networks.milkomedaC1Mainnet as string, label: 'Milkomeda', shortLabel: 'Milkomeda', chainId: 2001 },
};

type GetNetworks = {
  paymentTokenRoot?: string;
};

export const getNetworkOptions = ({ paymentTokenRoot }: GetNetworks): NetworkOption[] => {
  const bridgeAssets = getBridgeAssets();

  let networks: NetworkOption[] = [];

  if (paymentTokenRoot && bridgeAssets?.token[paymentTokenRoot]) {
    let temp = networks.concat(
      bridgeAssets.token[paymentTokenRoot].vaults
        .filter((vault) => vault.depositType === 'credit')
        .map((vault) => networkOptionsMap[vault.chainId])
    );
    networks = [...temp.slice(1), ...temp.slice(0, 1)];
  }

  return networks;
};
