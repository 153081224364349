import BigNumber from 'bignumber.js';

const check = ({ name }: { name: string }) => {
  if (!process.env[name]) {
    const str = `The environment variable${name ? ` ${name}` : ''} is not defined!`;
    // eslint-disable-next-line no-alert
    alert(str);
    throw new Error(str);
  }
  return false;
};

check({
  name: 'REACT_APP_LAUNCH_POOL_FACTORY',
});

check({
  name: 'REACT_APP_DEX_ROOT_ADDRESS',
});

export const LAUNCH_POOL_FACTORY = process.env.REACT_APP_LAUNCH_POOL_FACTORY as string;
export const DEX_ROOT_ADDRESS = process.env.REACT_APP_DEX_ROOT_ADDRESS as string;

const contractFee = 2_000_000_000;
export const CONTRACT_FEE = {
  EVER: contractFee / 10 ** 9,
  nanoEVER: contractFee,
};

const everWalletDeployFee = 10_000_000_000;
export const EVER_WALLET_DEPLOY_FEE = {
  EVER: everWalletDeployFee / 10 ** 9,
  nanoEVER: everWalletDeployFee,
};

const minCommission = 20_000_000;
export const MIN_COMMISSION = {
  EVER: minCommission / 10 ** 9,
  nanoEVER: minCommission,
};

export const evmAddresses: string[] = [
  '0x2E8d4ef87F45Fab04Fa6966c2Dd172514Bb4d753',
  '0x6a00edE3EE5c588ab70C8A8A51B739cE47344C23',
  '0x02A312C4303037F12aCfE3513e6B14a8d8DAEcF3',
  '0xe194Bf025a42AEB748872725DB999b7605560707',
  '0x08c9D0012f7B8C39b4E0ACFe4DBC7fBdd468A81A',
  '0xfB1Ec8f9D9482C7892917b36a096e4e46EAFDb68',
  '0x726457EAA1FC92A4CDd81f4EE04f0100fb20b069',
  '0x20E01A7B0ee1ddBdDeDeb51f06Af44278750F5BB',
  '0xf1b858E49A8704D6733DFBDF6eb9826B6C1B1edE',
  '0xEceCd5381A0a3786b6fe9860D5e1AaB9D83fe314',
];
