import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { info2, participantsIcon, totalSalesIcon } from '../../common/icons';
import { Context } from '../../providers/Provider';
import { formatAmount, formatPrice } from '../../utils/helpers';
import { useTooltip } from '../../utils/hooks/useTooltip';
import { ProjectType } from '../../ton/get-project';
import { Button } from '../button';
import { InvestmentProgressIndicator, InvestmentProgressIndicatorProps } from '../investment-progress-indicator';
import { Line } from '../line';
import CardHeader from './CardHeader';
import common from './common.module.css';
import { howItWorksText } from './how-it-works-text';
import InfoCard from './InfoCard';
import st from './the-high-sale-has-ended.module.css';
import { useEvmWallet } from '../../services/EvmWalletService';

export type TheHIGHSaleHasEndedProps = {
  participants: number;
  totalSales: {
    amount: number;
    currency: string;
  };
  isProjectActive: boolean;
  investButtonExist: boolean;
  project: ProjectType;

  investmentProgressIndicator: InvestmentProgressIndicatorProps;
};

const TheHIGHSaleHasEnded = ({
  participants,
  totalSales: { amount, currency },
  isProjectActive,
  investButtonExist,
  project,
  investmentProgressIndicator,
}: TheHIGHSaleHasEndedProps) => {
  const { htmlWidth, isMobile } = useContext(Context);
  const navigate = useNavigate();
  const { isConnected } = useEvmWallet();

  const { setTriggerRef, element } = useTooltip(
    <>
      {howItWorksText.map((p, i, arr) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          <div>
            {p.map((str, strI, strArr) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={strI}>
                <span>{str}</span>
                {strI < strArr.length - 1 && <br />}
              </span>
            ))}
          </div>
          {i < arr.length - 1 && <br />}
        </div>
      ))}
    </>
  );

  const calculateFallbackCondition = () => {
    return htmlWidth < 580;
  };

  return (
    <div>
      {element}
      <CardHeader title="Sale Details" growTitle>
        <>
          {/* <Button */}
          {/*  isCentred */}
          {/*  text={<span className={st.button}>{info2}</span>} */}
          {/*  fallback={calculateFallbackCondition() ? <span className={st.fallback}>{info2}</span> : undefined} */}
          {/*  ref={setTriggerRef} */}
          {/*  width="45px" */}
          {/*  onClick={(e) => { */}
          {/*    e.preventDefault(); */}
          {/*    e.stopPropagation(); */}
          {/*  }} */}
          {/* /> */}
          {investButtonExist && !isMobile && isConnected && (
            <Button
              isCentred
              text="Deposit"
              width="176px"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // setIsModalShow(true);
                navigate(`/project/${project.poolNumber}/deposit`);
              }}
            />
          )}
        </>
      </CardHeader>
      <div className={`${common.box} ${st.box}`}>
        <section className={common['box-section']}>
          <InfoCard
            title="Total Sales"
            text={formatPrice({
              amount,
              currency,
            })}
            icon={totalSalesIcon}
          />
        </section>
        <section className={common['box-section']}>
          <InfoCard title="Participants" text={formatAmount(participants)} icon={participantsIcon} />
        </section>
      </div>
      <InvestmentProgressIndicator {...investmentProgressIndicator} />
    </div>
  );
};

export default TheHIGHSaleHasEnded;
