/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
import { Web3ReactProvider } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import Web3 from 'web3';
import { Address } from 'everscale-inpage-provider';
import { CreditEthereumEventConfigurationDetails } from '../../bridge';
import { bridge } from '../../bridge/bridge';
import { getMaxAmount, getMinAmount, validate } from '../../bridge/calculations';
import { getAddresses } from '../../bridge/get-addresses';
import { getCurrencyOptions } from '../../bridge/get-currency-options';
import { PaymentSteps } from '../../bridge/payment-tracking';
import { subscriptionOnBridge } from '../../bridge/subscription-on-bridge';
import { getErc20Details, TokenDetails } from '../../bridge/web3';
import { CONTRACT_FEE, DEX_ROOT_ADDRESS, MIN_COMMISSION } from '../../constants/marketConstants';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import { getProject, getUserDataForProject, ProjectType } from '../../ton/get-project';
import { formatAmount } from '../../utils/helpers';
import { Button } from '../button';
import { Content } from '../content';
import { Input, Item, Select } from '../controls';
import { Option } from '../controls/Select';
import SubInput from '../controls/SubInput';
import { Popup } from '../popup';
import { Spinner } from '../spinner';
import { TransactionPopup } from '../transaction-popup';
import { TokenWallet } from '../../everscale';
import { transactionSubscriber } from '../../utils/transaction-subscribe';
import { getNetworkOptions, NetworkOption, networkOptionsMap, Networks } from '../../utils/get-networks';
import { UserInvestSmallCardProps } from '../small-card';
import { calculateUserFutureAllocation } from './calculations';
import ExtensionsManager from './ExtensionsManager';
import Wrapper from './wrapper';
import InvestSteps from './elems/invest-steps';
import st from './style.module.css';
import { getUserDepositLimit } from '../../ton/get-user-deposit-limit';
import { useCheckWhitelist } from '../../utils/hooks/useCheckWhitelist';
import useMetaMask from '../../utils/hooks/useMetaMask';
import { useEvmWallet } from '../../services/EvmWalletService';

const isDevMode = false;

const SEPARATOR = '__separator_';
const AMOUNT_XOR = 5;

const createHash = ({
  transactionHash,
  timestampMs,
  tonWalletAddress,
}: {
  transactionHash: string;
  timestampMs: number;
  tonWalletAddress: string;
}) => {
  try {
    const x = +`0x${tonWalletAddress.slice(-AMOUNT_XOR)}`;

    const hashString = `${transactionHash}${SEPARATOR}${timestampMs}`
      .split('')
      // eslint-disable-next-line no-bitwise, @typescript-eslint/no-non-null-assertion
      .map((value) => value!.codePointAt(0)! ^ x)
      .join('_');

    return btoa(hashString);
  } catch (error) {
    return null;
  }
};

const decodeHash = ({ crypted, tonWalletAddress }: { crypted: string; tonWalletAddress: string }) => {
  try {
    const x = +`0x${tonWalletAddress.slice(-AMOUNT_XOR)}`;
    const plain = atob(crypted);
    const decoded = plain
      .split('_')
      // eslint-disable-next-line no-bitwise
      .map((value) => +value ^ x)
      .map((value) => String.fromCodePoint(value))
      .join('');
    const [transactionHash, timestampMs] = decoded.split(SEPARATOR);
    return transactionHash && transactionHash.slice(0, 2) === '0x' && timestampMs
      ? {
          transactionHash,
          timestampMs,
        }
      : null;
  } catch (error) {
    return null;
  }
};

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      chainIdFromUrl: query.get('chainId') || undefined,
      currencyFromUrl: query.get('currency') || undefined,
      hashFromUrl: query.get('hash') || undefined,
    };
  }, [search]);
};

const getAmountInputErrorText = (
  amount: string,
  isEver: boolean,
  balance: BigNumber | null,
  minAmount?: BigNumber | null,
  maxAmount?: BigNumber | null
) => {
  let errorText = '';

  if (
    balance &&
    amount !== '0' &&
    (new BigNumber(amount).isGreaterThan(balance) || new BigNumber(amount).isNegative())
  ) {
    errorText = 'Insufficient balance';
  } else if (maxAmount && new BigNumber(amount).isGreaterThan(maxAmount)) {
    errorText = 'Vault limit exceeded';
  } else if (minAmount && new BigNumber(amount).plus(minAmount).isGreaterThan(balance || 0)) {
    errorText = 'Insufficient balance';
  }

  if (!errorText) return undefined;

  return <div className={st['error-text']}>{errorText}</div>;
};

// eslint-disable-next-line no-shadow
enum FreeTon {
  freeTon = 'ton',
}
const freeTonOption = { value: FreeTon.freeTon as string, label: 'EVER' };

type InvestProps = {
  project: ProjectType | null;
  userInfo: UserInvestSmallCardProps | null;
  isUserWhitelisted: boolean;
};

const Invest: React.FC<InvestProps> = observer(({ project, userInfo, isUserWhitelisted }) => {
  const navigate = useNavigate();
  const { address: account, chainId } = useEvmWallet();
  const { poolNumber: poolNumberFromUrl } = useParams();
  const { chainIdFromUrl, currencyFromUrl, hashFromUrl } = useQuery();
  const { TONBalance, accountInteraction, isLogin, isDeployed } = useContext(TONWalletContext);

  const [isLessThan10EversOnAccount, setIsLessThan10EversOnAccount] = useState(true);
  const [networkOptions, setNetworkOptions] = useState<NetworkOption[]>([]);
  const [network, setNetwork] = useState(networkOptions[0] || {});
  const [currency, setCurrency] = useState<Partial<Option> | undefined>();
  const [amount, setAmount] = useState('0');
  const [isModalShow, setIsModalShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenDetails, setTokenDetails] = useState<TokenDetails | null>(null);
  const [isGood, setIsGood] = useState(false);
  const [paymentStep, setPaymentStep] = useState<number | null>(0);
  const [paymentStepError, setPaymentStepError] = useState(false);
  const [transactionHash, setTransactionHash] = useState<{
    transactionHash: string;
    transactionMs: number;
  } | null>(null);
  const [infoForBridge, setInfoForBridge] = useState<{
    evm: {
      address: string;
      details: CreditEthereumEventConfigurationDetails;
    };
    vault: string;
    rootTokenContractAddress: string;
  } | null>(null);

  const [isUrlChecked, setIsUrlChecked] = useState(false);
  const [isInvest, setIsInvest] = useState(false);
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const [minAmount, setMinAmount] = useState<BigNumber | null>(null);
  const [maxAmount, setMaxAmount] = useState<BigNumber | null>(null);
  const [isMinAmountLoading, setIsMinAmountLoading] = useState(true);
  const [depositLimit, setDepositLimit] = useState<BigNumber | null>(null);
  const [userDepositLimit, setUserDepositLimit] = useState<BigNumber | null>(null);
  const [tmpHash, setTmpHash] = useState<string | null>(null);
  const [isHahsChecked, setIsHahsChecked] = useState(false);

  useEffect(() => {
    if (!account || !accountInteraction || window.localStorage.getItem(account.toLowerCase())) return;
    window.localStorage.setItem(account.toLowerCase(), accountInteraction.address.toString().toLowerCase());
  }, [account, accountInteraction]);

  /**
   * Reset form input and payment token info
   */
  useEffect(() => {
    setAmount('0');
    setTokenDetails(null);
  }, [currency, network, isGood, isLogin, isDeployed]);

  /**
   * Check if EVERs balance on the wallet is less than 10
   */
  useEffect(() => {
    if (!TONBalance) return;
    setIsLessThan10EversOnAccount(new BigNumber(TONBalance || 0).isLessThan(10));
  }, [TONBalance]);

  /** Get network options  */
  useEffect(() => {
    if (!project?.paymentTokenRoot) return;
    const options = getNetworkOptions({ paymentTokenRoot: project?.paymentTokenRoot });
    if (!network.value) {
      setNetwork(options[0] || {});
    }
    setNetworkOptions(options);
  }, [project?.paymentTokenRoot]);

  /**
   * Calculate user deposit limit
   */
  useEffect(() => {
    if (!project || !currency) return;
    getUserDepositLimit(project.poolNumber).then((limit) => {
      let availableUserLimit = new BigNumber(limit);
      const decimals = currency.tokenInfo?.decimals || 0;

      if (userInfo) {
        const userAlreadyDeposited = new BigNumber(userInfo.youInvested);
        availableUserLimit = availableUserLimit.minus(userAlreadyDeposited);
      }

      setUserDepositLimit(availableUserLimit.shiftedBy(-decimals));
    });
  }, [project, userInfo, currency]);

  // =========================

  useEffect(() => {
    if (isUrlChecked && isHahsChecked) {
      if (
        network?.chainId &&
        (network.chainId !== +String(chainIdFromUrl) || !chainIdFromUrl) &&
        !currency?.value &&
        !transactionHash
      ) {
        navigate(`/project/${poolNumberFromUrl}/deposit/?chainId=${network.chainId}`);
      } else if (!network?.chainId) {
        navigate(`/project/${poolNumberFromUrl}/deposit`);
      }
    }
  }, [isUrlChecked, isHahsChecked, network, currency]);
  useEffect(() => {
    if (
      isUrlChecked &&
      chainIdFromUrl &&
      (currency?.value ? currency.value !== currencyFromUrl || !currencyFromUrl : 0) &&
      isHahsChecked &&
      !transactionHash
    ) {
      navigate(
        `/project/${poolNumberFromUrl}/deposit/?chainId=${chainIdFromUrl}${
          currency?.value ? `&currency=${currency.value}` : ''
        }`
      );
    }
  }, [isUrlChecked, isHahsChecked, currency]);
  useEffect(() => {
    if (isUrlChecked && isHahsChecked && chainIdFromUrl && currencyFromUrl && isLogin && accountInteraction?.address) {
      let should = true;
      let decoded;
      if (hashFromUrl) {
        decoded = decodeHash({
          crypted: hashFromUrl,
          tonWalletAddress: String(accountInteraction.address),
        });
        if (!decoded) {
          setTransactionHash(null);
        } else if (decoded) {
          if (!decoded?.timestampMs) {
            decoded = null;
          } else if (transactionHash?.transactionHash && transactionHash.transactionHash === decoded?.transactionHash) {
            should = false;
          }
        }
      }
      if (should) {
        navigate(
          // eslint-disable-next-line max-len
          `/project/${poolNumberFromUrl}/deposit/?chainId=${chainIdFromUrl}&currency=${currencyFromUrl}${
            transactionHash
              ? `&hash=${createHash({
                  transactionHash: transactionHash.transactionHash,
                  timestampMs: transactionHash.transactionMs,
                  tonWalletAddress: String(accountInteraction.address),
                })}`
              : decoded
              ? `&hash=${createHash({
                  transactionHash: decoded.transactionHash,
                  timestampMs: +decoded.timestampMs,
                  tonWalletAddress: String(accountInteraction.address),
                })}`
              : ''
          }`
        );
      }
    }
    if (isLogin === false) {
      setTransactionHash(null);
      setTmpHash(hashFromUrl || null);
    }
  }, [isLogin, isUrlChecked, isHahsChecked, transactionHash]);

  useEffect(() => {
    if (isLogin && isUrlChecked && !hashFromUrl && tmpHash && accountInteraction?.address) {
      const decoded = decodeHash({
        crypted: tmpHash,
        tonWalletAddress: String(accountInteraction.address),
      });
      setTransactionHash(
        decoded
          ? {
              transactionHash: decoded.transactionHash,
              transactionMs: +decoded.timestampMs,
            }
          : null
      );
      setIsHahsChecked(true);
    }
  }, [isLogin, isUrlChecked]);

  useEffect(() => {
    if (!isUrlChecked && chainIdFromUrl && network.chainId !== +String(chainIdFromUrl)) {
      setNetwork(networkOptionsMap[chainIdFromUrl] || {});
      setIsUrlChecked(true);
    }
    if (hashFromUrl && isLogin && accountInteraction?.address) {
      const decoded = decodeHash({
        crypted: hashFromUrl,
        tonWalletAddress: String(accountInteraction.address),
      });
      if (decoded) {
        const { transactionHash: txHash, timestampMs: tms } = decoded;
        setTransactionHash(
          tms
            ? {
                transactionHash: txHash,
                transactionMs: +tms,
              }
            : null
        );
      } else setTransactionHash(null);

      if (!isUrlChecked) setIsUrlChecked(true);
      if (!isHahsChecked) setIsHahsChecked(true);
    }
    if (!isUrlChecked && !chainIdFromUrl && !currencyFromUrl && !hashFromUrl) {
      setIsUrlChecked(true);
    }
    if (!isHahsChecked && !hashFromUrl) {
      setIsHahsChecked(true);
    }
  }, [isLogin, chainIdFromUrl, currencyFromUrl, hashFromUrl]);

  // =========================

  /**
   * Get addresses for bridge transfer
   */
  useEffect(() => {
    if (
      network.value === Networks.freeTon ||
      !network.chainId ||
      !currency ||
      !currency?.bridge?.vaults[0] ||
      currency.chainId !== Number(chainId)
    )
      return;

    const { ethereumConfiguration: evmConfigAddress, vault } = currency.bridge.vaults[0];
    getAddresses({ evmConfigAddress, chainId })
      .then((result) => {
        if (!result) throw Error('getAddresses returned an empty result');
        setInfoForBridge({
          evm: {
            address: evmConfigAddress,
            details: result.evmConfigDetails,
          },
          vault,
          rootTokenContractAddress: result.rootTokenContractAddress,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [currency, isGood]);

  /**
   * Set initial currency (USDT payment token)
   */
  useEffect(() => {
    if (!project || !chainIdFromUrl) return;

    setCurrency(undefined);
    setInfoForBridge(null);

    getCurrencyOptions({
      chainId: +chainIdFromUrl,
      paymentTokenRoot: project?.paymentTokenRoot,
    })
      .then((tokensInfo) => {
        setCurrency(tokensInfo?.[0]);
        setTokenDetails({
          decimals: tokensInfo?.[0].tokenInfo.decimals || 0,
          tokenBalance: new BigNumber(0),
          vaultBalance: new BigNumber(0),
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [project, chainIdFromUrl, network]);

  /**
   * Get ERC20 token details by currency meta
   */
  useEffect(() => {
    const vault = currency?.bridge?.vaults?.[0]?.vault;
    if (network.value === Networks.freeTon || !currency || !vault || !account || !infoForBridge) return;

    setTokenDetails(null);
    setIsMinAmountLoading(true);

    getErc20Details({ vault, walletAddress: account })
      .then(async (details) => {
        if (infoForBridge && currency?.tokenInfo?.decimals && details?.decimals) {
          const maxAmountResult = await getMaxAmount({
            evmDecimals: details.decimals,
            tip3Decimals: currency.tokenInfo.decimals,
            userBalance: details.tokenBalance,
            vaultBalance: details.vaultBalance,
            vault: infoForBridge.vault,
          });

          const minAmountResult = await getMinAmount({
            dexRoot: DEX_ROOT_ADDRESS,
            tokenRoot: infoForBridge.rootTokenContractAddress,
            evmDecimals: details?.decimals,
            tip3Decimals: currency?.tokenInfo?.decimals,
            vault: infoForBridge.vault,
            TONBalance,
            isEverWalletDeployed: !!isDeployed,
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            return null;
          });

          setTokenDetails(details);
          setMaxAmount(maxAmountResult);
          if (minAmountResult) {
            setMinAmount(minAmountResult.minAmount);
            setDepositLimit(minAmountResult.depositLimit);
          }
          setIsMinAmountLoading(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [currency?.chainId, infoForBridge]);

  useEffect(() => {
    if (network.value === Networks.freeTon || !isInvest || !transactionHash || !infoForBridge) return;

    setPaymentStep(null);
    setPaymentStepError(false);
    setIsSuccess(null);
    setIsLoading(true);
    setIsModalShow(false);
    subscriptionOnBridge({
      evmConfigAddress: infoForBridge.evm.address,
      evmConfigDetails: infoForBridge.evm.details,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCreditProcessorStatusChanged: () => {},
      onTrackThePayment: (step) => {
        if (step === PaymentSteps.error) {
          setPaymentStepError(true);
          setIsSuccess(false);
          setIsLoading(false);
          setIsModalShow(true);
          return;
        }
        setPaymentStep(step);
      },
      transactionHash: transactionHash.transactionHash,
    });
  }, [isInvest, transactionHash]);

  const onInvest = useCallback(() => {
    if (!accountInteraction?.address || !project) {
      setIsLoading(false);
      return;
    }

    setIsSuccess(null);
    setIsLoading(true);
    setIsModalShow(false);

    transactionSubscriber({
      accountInteractionAddress: accountInteraction.address,
      callbackId: project.callId,
      transactionMethods: ['newInvestmentCallback', 'investmentDeclinedCallback'],
      callbackFn: ({ isSuccessCallback, isDeclineCallback }) => {
        setIsSuccess(isDeclineCallback ? false : isSuccessCallback);
        setIsLoading(false);
        setIsModalShow(true);
      },
    });

    if (network.value === Networks.freeTon) {
      TokenWallet.send({
        withDerive: true,
        address: new Address(project.paymentTokenRoot),
        recipient: new Address(project.launchPoolAddress),
        owner: accountInteraction.address,
        tokens: new BigNumber(amount).shiftedBy(project.mainCurrency.decimals).toString(),
        payload: project.cell,
      }).catch((error) => {
        let errorObject;
        try {
          errorObject = JSON.parse(error.message);
          // eslint-disable-next-line no-empty
        } catch (err) {}
        if (+errorObject?.code === 3) {
          // eslint-disable-next-line no-console
          console.error(errorObject.message);
        } else {
          setIsSuccess(false);
          setIsModalShow(true);
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
    } else if (infoForBridge && account && network.chainId) {
      setIsInvest(true);
      setTransactionHash(null);
      bridge({
        chainId: network.chainId,
        onTransactionHash: (_hash) =>
          setTransactionHash({
            transactionHash: _hash,
            transactionMs: +Date.now(),
          }),
        amount,
        evmDecimals: tokenDetails?.decimals || 0,
        tip3Decimals: currency?.tokenInfo?.decimals || 0,
        evmWallet: account,
        recipient: project.launchPoolAddress,
        user: String(accountInteraction.address),
        cell: project.cell,
        vault: infoForBridge.vault,
        maxBridgeFee: minAmount,
        isLessThan10EversOnAccount,
      })
        .then((x) => {
          if (!x) {
            setIsLoading(false);
            setIsModalShow(true);
            setIsSuccess(false);
          }
        })
        .catch((error) => {
          let errorObject;
          try {
            errorObject = JSON.parse(error.message);
            // eslint-disable-next-line no-empty
          } catch (err) {}
          if (+errorObject?.code === 4001) {
            // eslint-disable-next-line no-console
            // console.error(errorObject.message);

            setIsInvest(false);
            setIsLoading(false);
            setIsModalShow(false);
          } else {
            setIsSuccess(false);
            setIsModalShow(true);
            // eslint-disable-next-line no-console
            console.error(error);
          }
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [accountInteraction, project, amount, network, currency, TONBalance]);

  useEffect(() => {
    if (network.value === Networks.freeTon && project && accountInteraction && currency?.tokenInfo?.decimals) {
      const tip3Decimals = currency.tokenInfo.decimals;
      TokenWallet.balanceByTokenRoot(accountInteraction?.address, new Address(project.paymentTokenRoot)).then((b) => {
        setBalance(new BigNumber(b).shiftedBy(-tip3Decimals));
      });
    } else if (network.value !== Networks.freeTon && tokenDetails) {
      setBalance(tokenDetails.tokenBalance.shiftedBy(-tokenDetails.decimals));
    }
  }, [network, currency, tokenDetails, isLogin, infoForBridge]);

  return (
    <Popup
      className={st['invest-popup']}
      sectionClassName={st['invest-popup-section']}
      buttons={
        <>
          {isModalShow && (
            <TransactionPopup
              isSuccess={!!isSuccess}
              onCancel={() => navigate(`/project/${project?.poolNumber ?? ''}`)}
            />
          )}

          {isLoading && (
            <InvestSteps
              isLogin={isLogin}
              paymentStep={paymentStep || 0}
              paymentStepError={paymentStepError}
              network={network}
              transactionHash={transactionHash?.transactionHash}
            />
          )}

          <Button
            isDisabled={
              !isUserWhitelisted ||
              (project ? !isDevMode && project.endTime * 1000 < Date.now() : true) ||
              +amount <= 0 ||
              !(
                isGood &&
                amount &&
                balance instanceof BigNumber &&
                new BigNumber(amount).isLessThanOrEqualTo(balance) &&
                (network.chainId
                  ? typeof tokenDetails?.decimals === 'number' &&
                    depositLimit instanceof BigNumber &&
                    minAmount instanceof BigNumber &&
                    maxAmount instanceof BigNumber &&
                    validate({
                      amount: new BigNumber(amount),
                      userEvmBalance: balance,
                      depositLimit,
                      minAmount,
                      maxAmount,
                    })
                  : new BigNumber(amount).isGreaterThan(0))
              )
            }
            isInvert={!'todo'}
            isCentred
            text="Deposit"
            width="145px"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onInvest();
            }}
          />
        </>
      }
    >
      <Item text="Connect wallet">
        <ExtensionsManager
          network={network.value === Networks.freeTon || !isLogin ? 'free-ton' : 'meta-mask'}
          networkChainId={network.chainId}
          setIsGood={(bool) => setIsGood(bool)}
        />
      </Item>
      <Item text="Network">
        <Select isDisabled={false} options={networkOptions} initialOption={network} setCurrentOption={setNetwork} />
      </Item>
      <Item text="Currency">
        <Select options={currency ? [currency] : []} initialOption={currency} isLoading={!currency} />
      </Item>
      <Item
        text="Amount"
        anchor={
          userDepositLimit && currency ? (
            <div className={st.balance}>
              Limit:{' '}
              {userDepositLimit ? (
                <span>
                  {balance instanceof BigNumber
                    ? formatAmount(userDepositLimit, '.', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 8,
                      })
                    : '0'}{' '}
                  {currency?.label || ''}
                </span>
              ) : (
                <Spinner withWrapper backgroundColor="transparent" width={16} padding={0} />
              )}
            </div>
          ) : undefined
        }
      >
        <Input
          currency={`${currency?.value}${isLogin}${isDeployed}` || ''}
          isEver={network.value === Networks.freeTon}
          isDisabled={
            !isGood ||
            !!userDepositLimit?.isLessThanOrEqualTo(0) ||
            !!balance?.isLessThanOrEqualTo(0) ||
            !(
              isGood &&
              (network.chainId
                ? depositLimit instanceof BigNumber && balance instanceof BigNumber && minAmount instanceof BigNumber
                : balance instanceof BigNumber && balance.isGreaterThan(0))
            )
          }
          isLoading={network.value !== Networks.freeTon ? currency && isMinAmountLoading : false}
          amount={amount}
          userDepositLimit={userDepositLimit}
          setAmount={(x) => {
            setAmount((old) => (x.slice(-1) === '.' ? old : x === old ? old : x));
          }}
          fixTo={Math.min(currency?.tokenInfo?.decimals || 0, tokenDetails?.decimals || 2)}
        >
          {getAmountInputErrorText(amount, isLessThan10EversOnAccount, balance, minAmount, maxAmount)}
        </Input>
        <SubInput
          info={
            currency &&
            isGood &&
            (network.value === Networks.freeTon ? isLogin : isLogin && account) &&
            amount &&
            balance &&
            new BigNumber(amount).isGreaterThan(0) &&
            new BigNumber(amount).isLessThanOrEqualTo(balance) &&
            (network.value !== Networks.freeTon ? !(currency && isMinAmountLoading) : true)
              ? [
                  {
                    // EVER
                    type: 'line',
                    element: {
                      title: 'Max fee:',
                      value: `${CONTRACT_FEE.EVER} ${freeTonOption.label}`,
                    },
                    isShow: network.value === Networks.freeTon,
                  },
                  {
                    // BSC
                    type: 'line',
                    element: {
                      title: 'Max fee:',
                      value: `${minAmount?.toString() ?? ''} ${currency.label}`,
                    },
                    isShow: !!(network.value !== Networks.freeTon && amount && minAmount),
                  },
                  {
                    // BSC
                    type: 'line',
                    element: {
                      title: 'Minimum 10 EVER will be transferred to your wallet',
                      value: null,
                    },
                    isShow: !!(
                      network.value !== Networks.freeTon &&
                      amount &&
                      (isLessThan10EversOnAccount || !isDeployed)
                    ),
                  },
                  {
                    type: 'line',
                    element: {
                      title: 'Allocation:',
                      value:
                        project && currency?.tokenInfo?.decimals ? (
                          <span>
                            {calculateUserFutureAllocation(
                              new BigNumber(amount),
                              new BigNumber(project.totalRaised),
                              new BigNumber(userInfo?.youInvested || 0),
                              currency.tokenInfo.decimals
                            )}{' '}
                            %
                          </span>
                        ) : (
                          <Spinner withWrapper backgroundColor="transparent" padding={0} width={16} color="#000" />
                        ),
                    },
                    isShow: new BigNumber(amount).isGreaterThan(0),
                  },
                ]
              : []
          }
        />
      </Item>
    </Popup>
  );
});

const instructionList = [
  { number: 1, text: 'Connect or install Everwallet chrome extension or app' },
  { number: 2, text: 'Connect or install EVM wallet, e.g. Metamask' },
  { number: 3, text: 'Select the blockchain you want to transfer tokens from' },
  { number: 4, text: 'Enter the amount of tokens you want to deposit' },
  { number: 5, text: 'Confirm deposit' },
];

const Component = () => {
  const { address } = useEvmWallet();
  const { accountInteraction } = useContext(TONWalletContext);
  const { poolNumber: poolNumberFromUrl } = useParams();
  const navigate = useNavigate();
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [project, setProject] = useState<ProjectType | null>(null);
  const [userInfo, setUserInfo] = useState<UserInvestSmallCardProps | null>(null);
  const { isUserWhitelisted } = useCheckWhitelist(
    address?.toLowerCase(),
    accountInteraction?.address.toString().toLowerCase()
  );

  useEffect(() => {
    if (typeof poolNumberFromUrl === 'undefined') {
      navigate(`/`);
    } else {
      getProject({
        poolNumber: +poolNumberFromUrl,
        userAddress: accountInteraction?.address.toString(),
      })
        .then((projectResult) => {
          setProject(projectResult);
          return projectResult;
        })
        .then((projectResult) => {
          if (!projectResult) return null;
          return getUserDataForProject({
            project: projectResult,
            userAccountAddress: accountInteraction?.address.toString() || '',
          });
        })
        .then(setUserInfo)
        .finally(() => setIsProjectLoading(false));
    }
  }, [poolNumberFromUrl, accountInteraction?.address]);

  const isDepositNotAllowed = !(
    project &&
    Date.now() > +project.startTime * 1000 &&
    Date.now() < +project.endTime * 1000
  );

  if (!isProjectLoading && (isDepositNotAllowed || !isUserWhitelisted))
    return (
      <Content backTo={`/project/${poolNumberFromUrl ?? ''}`} title="Back">
        <Wrapper>
          <Popup
            buttons={
              <Button
                isInvert
                isCentred
                text="Cancel"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/project/${project?.poolNumber ?? ''}`);
                }}
              />
            }
          >
            <>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <h1>Deposits unavailable</h1>
              {!!project?.startTime &&
                Date.now() < +project.startTime * 1000 &&
                !!project?.endTime &&
                Date.now() > project.endTime * 1000 && (
                  <>
                    <span>Because current date</span>
                    <span>
                      {!!project?.startTime && Date.now() < +project.startTime * 1000
                        ? ' smaller than project start time'
                        : ''}
                      {!!project?.endTime && Date.now() > project.endTime * 1000 ? ' bigger than project end time' : ''}
                    </span>
                  </>
                )}
              {!isUserWhitelisted && (
                <span style={{ marginBottom: 50, textAlign: 'center' }}>
                  Only whitelisted users can participate in community round. If there is an error please contact our
                  support in{' '}
                  <a
                    style={{ color: 'rgba(39, 110, 245, 1)' }}
                    href="https://discord.gg/amazyio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    our discord
                  </a>
                  .
                </span>
              )}
            </>
          </Popup>
        </Wrapper>
      </Content>
    );

  return (
    <Content backTo={`/project/${poolNumberFromUrl ?? ''}`} title="Back">
      <Wrapper>
        {isProjectLoading ? (
          <Popup>
            <Spinner withWrapper backgroundColor="transparent" />
          </Popup>
        ) : (
          <>
            <div className={`${st.instruction} ${st['invest-popup']}`}>
              {instructionList.map((item) => (
                <div className={st['instruction-item-wrapper']} key={item.number}>
                  <div className={st.number}>{item.number}</div>
                  <div className={st.text}>{item.text}</div>
                </div>
              ))}
            </div>
            <Invest project={project} userInfo={userInfo} isUserWhitelisted={isUserWhitelisted} />
          </>
        )}
      </Wrapper>
    </Content>
  );
};

const getLibrary = (provider: any) => new Web3(provider);

export default () => {
  const { isDeployed } = useContext(TONWalletContext);
  useMetaMask();
  const [inner, setInner] = useState<boolean | undefined>();
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (typeof isDeployed === 'boolean') setInner(isDeployed);
  }, [isDeployed]);

  useEffect(() => {
    if (typeof isDeployed === 'boolean' && isDeployed !== inner) {
      setCounter((old) => old + 1);
    }
  }, [isDeployed, inner]);

  const memoized = useMemo(
    () => (
      <Web3ReactProvider getLibrary={getLibrary}>
        <Component />
      </Web3ReactProvider>
    ),
    [counter]
  );

  return <>{memoized}</>;
};
