import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router';
import { TONWalletContext } from '../../providers/TONWalletProvider';
import { VestingPeriods } from '../../ton/utils/LaunchPool';
import { formatAmount, formatPriceNew } from '../../utils/helpers';
import { List } from '../list';
import { ListItem } from '../list/List';
import { Status } from '../status';
import { StatusEnum } from '../status/Status';
import st from './style.module.css';
import { Button } from '../button';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BGI from '../../../public/bgi.jpg';
import { useEvmWallet } from '../../services/EvmWalletService';

export type Currency = {
  symbol: string;
  decimals: number;
};

export type CardProps = {
  tokenCurrency: Currency & {
    launchTokenRoot: string;
  };
  mainCurrency: Currency;
};

export type Period = {
  amount: number;
  unfreezeTime: number;
};

export type UserInvestCardProps = {
  youInvested: number;
  appreciated: number;
  appreciatedPercent: number;
  accessible: number;
  _return: number;
  received: number;

  launchPoolParticipationAddress: string;

  periods: Period[];
};

export type CommonCardProps = {
  owner: string;

  cell: string;
  callId: string;
  launchTokenRoot: string;
  paymentTokenRoot: string;

  isEmbed?: boolean;
  logoUrl: string;
  status: StatusEnum;
  title: string;
  text: string;
  linkTo?: string;
  children?: JSX.Element;

  tokenForSale: number;
  softcap: number;
  hardcap: number;
  startTime: number;
  endTime: number;

  amountOfInvestors: number;
  totalRaised: number;
  totalWithdraw: number;
  tokensSupply: number;
  vestingPeriods: VestingPeriods[];
};

const Card = ({
  tokenCurrency,
  mainCurrency,
  logoUrl,
  status,
  title,
  text,
  children,
  isEmbed = false,
  tokenForSale,
  softcap,
  hardcap,
  youInvested,
  appreciated,
  appreciatedPercent,
  accessible,
  _return,
  received,
  endTime,
  projectCardLink,
  totalRaised,
}: CommonCardProps &
  Partial<CardProps> &
  Partial<UserInvestCardProps> & {
    projectCardLink?: string;
  }) => {
  const { isLogin } = useContext(TONWalletContext);
  const { isConnected } = useEvmWallet();
  const navigate = useNavigate();
  const [list, setList] = useState<ListItem[]>([]);

  useEffect(() => {
    const innerList: ListItem[] = [];
    if (!isEmbed && tokenCurrency && mainCurrency) {
      if (typeof tokenForSale === 'number')
        innerList.push({
          title: 'Token For Sale',
          data: formatPriceNew({
            amount: new BigNumber(tokenForSale).shiftedBy(-tokenCurrency.decimals).toNumber(),
            currency: tokenCurrency.symbol,
          }),
        });
      if (typeof softcap === 'number')
        innerList.push({
          title: 'Soft cap',
          data: formatPriceNew({
            amount: new BigNumber(softcap).shiftedBy(-mainCurrency.decimals).toNumber(),
            currency: mainCurrency.symbol,
          }),
        });
      if (typeof hardcap === 'number')
        innerList.push({
          title: 'Hard cap',
          data: formatPriceNew({
            amount: new BigNumber(hardcap).shiftedBy(-mainCurrency.decimals).toNumber(),
            currency: mainCurrency.symbol,
          }),
        });
      if (totalRaised)
        innerList.push({
          title: 'Committed',
          data: formatPriceNew({
            amount: new BigNumber(totalRaised).shiftedBy(-mainCurrency.decimals).toNumber(),
            currency: mainCurrency.symbol,
          }),
        });
      if (isLogin && youInvested && youInvested > 0) {
        const isFinished = status === 'Finished' || status === 'Failed';
        if (typeof youInvested === 'number')
          innerList.push({
            title: 'Your deposit',
            data: formatPriceNew({
              amount: new BigNumber(youInvested).shiftedBy(-mainCurrency.decimals).toNumber(),
              currency: mainCurrency.symbol,
            }),
          });
        if (typeof appreciated === 'number' && appreciated > 0)
          innerList.push({
            title: 'Your allocation',
            data: (
              <>
                {formatPriceNew({
                  amount: new BigNumber(appreciated).shiftedBy(-tokenCurrency.decimals).toNumber(),
                  currency: tokenCurrency.symbol,
                })}
                {appreciatedPercent ? ` (${formatAmount(appreciatedPercent)}%)` : ''}
              </>
            ),
          });
        if (
          totalRaised >= softcap &&
          status === 'Finished' &&
          typeof accessible === 'number' &&
          accessible >= 0 &&
          Date.now() > endTime * 1000
        )
          innerList.push({
            title: 'You can claim now',
            data: formatPriceNew({
              amount: new BigNumber(accessible).shiftedBy(-tokenCurrency.decimals).toNumber(),
              currency: tokenCurrency.symbol,
            }),
          });
        if (
          totalRaised >= softcap &&
          status === 'Finished' &&
          typeof received === 'number' &&
          received > 0 &&
          Date.now() > endTime * 1000
        )
          innerList.push({
            title: 'Received',
            data: formatPriceNew({
              amount: new BigNumber(received).shiftedBy(-tokenCurrency.decimals).toNumber(),
              currency: tokenCurrency.symbol,
            }),
          });
        if (isFinished && typeof _return === 'number' && _return > 0 && Date.now() > endTime * 1000)
          innerList.push({
            title: 'Return',
            data: formatPriceNew({
              amount: new BigNumber(_return).shiftedBy(-mainCurrency.decimals).toNumber(),
              currency: mainCurrency.symbol,
            }),
          });
      }
    }
    setList(innerList);
  }, [
    isEmbed,
    isLogin,
    logoUrl,
    status,
    title,
    text,
    children,
    tokenForSale,
    softcap,
    hardcap,
    youInvested,
    appreciated,
    accessible,
    _return,
    received,
    mainCurrency,
    tokenCurrency,
  ]);

  const mainElement = useMemo(
    () => (
      <div className={st.card + (isEmbed ? ` ${st.embed}` : '')}>
        <section className={st['top-section']} style={logoUrl ? { backgroundImage: `url("${BGI}")` } : {}}>
          <span className={st.status}>
            <Status type={status} />
          </span>
        </section>
        <div className={st['padding-wrapper']}>
          {isEmbed && <section className={st.globe}>{children}</section>}
          <div className={st.title}>{title}</div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={`${st.text} ${isEmbed ? '' : st.cut}`} dangerouslySetInnerHTML={{ __html: text }} />
          <section className={st['list-section']}>
            <List list={list || []} />
          </section>
          {status === 'Live' && endTime * 1000 > Date.now() && projectCardLink && isConnected && (
            <Button
              isCentred
              text="Deposit"
              width="176px"
              className={st['deposit-button']}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`${projectCardLink}/deposit`);
              }}
            />
          )}
        </div>
      </div>
    ),
    [isEmbed, logoUrl, status, list, children]
  );
  return (
    <>
      {projectCardLink ? (
        <NavLink className={st['link-to']} to={projectCardLink}>
          {mainElement}
        </NavLink>
      ) : (
        mainElement
      )}
    </>
  );
};
export default Card;
